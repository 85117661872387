import { useSelector } from 'react-redux';
import * as Yup from 'yup';

const user = (target) => {
  const { role } = useSelector((state) => state.auth.user);

  const minRole = role + 1;

  const valuesList = [
    {
      name: 'name',
      text: 'Name',
      initialValue: target?.name,
    },
    {
      name: 'username',
      text: 'Email',
      type: 'email',
      initialValue: target?.username,
    },
    {
      name: 'role',
      text: 'Role',
      type: 'number',
      min: minRole,
      initialValue: target?.role || minRole,
    },
  ];

  const validationSchema = Yup.object({
    name: Yup
      .string()
      .required('Name is required'),
    username: Yup
      .string()
      .email('Invalid email')
      .required('Username is required'),
    role: Yup
      .number()
      .min(minRole, `You can only add user with role >= ${minRole}`)
      .required('Role is required'),
  });

  return { valuesList, validationSchema };
};

const group = (target) => {
  const valuesList = [
    {
      name: 'name',
      text: 'Name',
      initialValue: target?.name,
    },
    {
      name: 'active',
      text: 'Active',
      type: 'checkbox',
      initialValue: target?.active,
    },
  ];

  const validationSchema = Yup.object({
    name: Yup
      .string()
      .required('Name is required'),
  });

  return { valuesList, validationSchema };
};

const customer = (target) => {
  const { groups } = useSelector((state) => state);

  const valuesList = [
    {
      name: 'groupId',
      text: 'Group',
      select: true,
      initialValue: target?.groupId.toString() || '0',
      options: groups,
    },
    {
      name: 'name',
      text: 'Name',
      initialValue: target?.name,
    },
    {
      name: 'company',
      text: 'Company Name',
      initialValue: target?.company || '',
    },
    {
      name: 'email',
      text: 'Email',
      type: 'email',
      initialValue: target?.email,
    },
    {
      name: 'phone',
      text: 'Phone',
      initialValue: target?.phone,
    },
    {
      name: 'address',
      text: 'Address',
      initialValue: target?.address,
    },
    {
      name: 'zip',
      text: 'Zip',
      initialValue: target?.zip,
    },
    {
      name: 'city',
      text: 'City',
      initialValue: target?.city,
    },
    {
      name: 'area',
      text: 'Area',
      initialValue: target?.area,
    },
    {
      name: 'time',
      text: 'Time',
      type: 'time',
      initialValue: target?.time,
    },
    {
      name: 'delivery_fee_threshold',
      text: 'Delivery Fee Threshold (VAT included)',
      type: 'number',
      initialValue: target?.delivery_fee_threshold || '0',
      min: '0',
      step: '1',
    },
    {
      name: 'delivery_fee_vat_inc',
      text: 'Delivery Fee (VAT included)',
      type: 'number',
      initialValue: target?.delivery_fee_vat_inc || '0',
      min: '0',
      step: '0.01',
    },
    {
      name: 'active',
      text: 'Active',
      type: 'checkbox',
      initialValue: target?.active,
    },
  ];

  const validationSchema = Yup.object({
    groupId: Yup
      .string()
      .notOneOf(['0'])
      .required('Group is required'),
    name: Yup
      .string()
      .required('Name is required'),
    email: Yup
      .string()
      .email('Invalid Email'),
    zip: Yup
      .string()
      .length(5),
    delivery_fee_vat_inc: Yup
      .number()
      .min(0, 'Min value is 0'),
    delivery_fee_threshold: Yup
      .number()
      .integer('Must be a whole number')
      .min(0, 'Min value is 0'),
  });

  return { valuesList, validationSchema };
};

const productType = (target) => {
  const valuesList = [
    {
      name: 'name',
      text: 'Name',
      initialValue: target?.name,
    },
    {
      name: 'active',
      text: 'Active',
      type: 'checkbox',
      initialValue: target?.active,
    },
  ];

  const validationSchema = Yup.object({
    name: Yup
      .string()
      .required('Name is required'),
  });

  return { valuesList, validationSchema };
};

const product = (target) => {
  const { productTypes } = useSelector((state) => state);

  const valuesList = [
    {
      name: 'productTypeId',
      text: 'Type',
      select: true,
      initialValue: target?.productTypeId.toString() || '0',
      options: productTypes,
    },
    {
      name: 'name',
      text: 'Name',
      initialValue: target?.name,
    },
    {
      name: 'name_cn',
      text: 'Name CN',
      initialValue: target?.name_cn,
    },
    {
      name: 'ean',
      text: 'EAN',
      initialValue: target?.ean,
    },
    {
      name: 'unitPriceByOrder',
      text: 'Order Price',
      initialValue: target?.unitPriceByOrder || '0',
      type: 'number',
      min: '0',
      step: '0.01',
    },
    {
      name: 'unitPriceByDeliver',
      text: 'Deliver Price',
      initialValue: target?.unitPriceByDeliver || '0',
      type: 'number',
      min: '0',
      step: '0.01',
    },
    {
      name: 'active',
      text: 'Active',
      type: 'checkbox',
      initialValue: target?.active,
    },
  ];

  const validationSchema = Yup.object({
    productTypeId: Yup
      .string()
      .notOneOf(['0'])
      .required('Type is required'),
    name: Yup
      .string()
      .required('Name is required'),
    ean: Yup
      .string()
      .required('EAN is required'),
    unitPriceByOrder: Yup
      .number()
      .required('Required')
      .min(0, 'Min value is 0'),
    unitPriceByDeliver: Yup
      .number()
      .required('Required')
      .min(0, 'Min value is 0'),
  });

  return { valuesList, validationSchema };
};

export {
  user,
  group,
  customer,
  productType,
  product,
};
