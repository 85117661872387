import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
/* Icons */
import DoneIcon from '@mui/icons-material/Done';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import StoreIcon from '@mui/icons-material/Store';
import MapIcon from '@mui/icons-material/Map';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PlaceIcon from '@mui/icons-material/Place';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EventIcon from '@mui/icons-material/Event';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import CircleIcon from '@mui/icons-material/Circle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BusinessIcon from '@mui/icons-material/Business';
/* End of icons */
import { openModal } from '../reducers/modal';
import { StyledCustomers, StyledSelectGroup } from './styles';

// Explicitly set the default filter with showInactive as false
const DEFAULT_FILTER = { group: '0', city: 'all', showInactive: false };

const Customers = () => {
  const pageSize = 15;
  const dispatch = useDispatch();
  const { groups, customers, auth } = useSelector((state) => state);
  const [cities, setCities] = useState([]);
  const [customersToShow, setCustomersToShow] = useState([]);

  // Load filter from localStorage or use default values
  const [filter, setFilter] = useState(() => {
    try {
      const savedFilter = localStorage.getItem('customersFilter');
      return savedFilter ? JSON.parse(savedFilter) : DEFAULT_FILTER;
    } catch (error) {
      console.error('Error loading filter from localStorage:', error);
      return DEFAULT_FILTER;
    }
  });

  const [groupsPageNumber, setGroupsPageNumber] = useState(0);
  const [customersMaxPageNumber, setCustomersMaxPageNumber] = useState(0);
  const groupsMaxPageNumber = Math.ceil(groups.length / pageSize);
  const [customersPageNumber, setCustomersPageNumber] = useState(0);

  // Save filter to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('customersFilter', JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    setCustomersToShow(customers);
    setCities([...new Set(customers.map((item) => item.city))]);
    setCustomersMaxPageNumber(Math.ceil(customers.length / pageSize));
  }, [customers]);

  // Make sure the filter is applied correctly when customers change
  useEffect(() => {
    applyFilters();
  }, [customers]);

  // Apply all filters
  const applyFilters = () => {
    let customersAfterFilter = customers;

    // group
    if (filter.group !== '0') {
      customersAfterFilter = customersAfterFilter.filter((item) => item.groupId === +filter.group);
    }

    setCities([...new Set(customersAfterFilter.map((item) => item.city))]);

    // city
    if (filter.city !== 'all') {
      customersAfterFilter = customersAfterFilter.filter((item) => item.city === filter.city);
    }

    // active status
    if (!filter.showInactive) {
      customersAfterFilter = customersAfterFilter.filter((item) => item.active);
    }

    setCustomersMaxPageNumber(Math.ceil(customersAfterFilter.length / pageSize));
    setCustomersToShow(customersAfterFilter);
    setCustomersPageNumber(0);
  };

  // Update the existing useEffect to use the applyFilters function
  useEffect(() => {
    applyFilters();
  }, [filter]);

  const groupFilter = ({ target }) => {
    setFilter({ group: target.value, city: 'all' });
  };

  const cityFilter = ({ target }) => {
    setFilter({ ...filter, city: target.value });
  };

  const switchModal = (type, category, target) => {
    dispatch(openModal({ type, category, target }));
  };

  const toggleInactive = () => {
    setFilter({ ...filter, showInactive: !filter.showInactive });
  };

  return (
    <StyledCustomers>
      <div className="groupTable">
        <div className="tableTitle">
          <div>Groups</div>
          <AddCircleIcon type="button" onClick={() => switchModal('add', 'group')} />
        </div>
        <div className="tableHeader">
          <div>Name</div>
        </div>
        <div className="tableBody">
          {groups
            .slice(groupsPageNumber * pageSize, (groupsPageNumber + 1) * pageSize)
            .map((group) => (
              <div
                key={group.name}
                className={`row ${auth.user.role < 2 ? 'editable' : ''}`}
                role="presentation"
                onClick={() => switchModal('edit', 'group', group)}
              >
                <div>{group.name}</div>
              </div>
            ))}
        </div>
        <div className="tableFooter">
          <div className="pageGroup">
            <button
              type="button"
              onClick={() => setGroupsPageNumber(groupsPageNumber - 1)}
              disabled={groupsPageNumber < 1}
            >
              {'<'}
            </button>
            <div>{`${Math.min(groupsPageNumber + 1, groupsMaxPageNumber)} / ${groupsMaxPageNumber}`}</div>
            <button
              type="button"
              onClick={() => setTypesPageNumber(groupsPageNumber + 1)}
              disabled={groupsPageNumber + 1 >= groupsPageNumber}
            >
              {'>'}
            </button>
          </div>
        </div>
      </div>
      <div className="customerTable">
        <div className="tableTitle">
          <div>Customers</div>
          <div className="titleActions">
            {filter.showInactive ? (
              <VisibilityIcon
                type="button"
                onClick={toggleInactive}
                titleAccess="Hide inactive customers"
                style={{ marginRight: '10px', cursor: 'pointer' }}
              />
            ) : (
              <VisibilityOffIcon
                type="button"
                onClick={toggleInactive}
                titleAccess="Show inactive customers"
                style={{ marginRight: '10px', cursor: 'pointer' }}
              />
            )}
            <AddBusinessIcon type="button" onClick={() => switchModal('add', 'customer')} />
          </div>
        </div>
        <div className="tableHeader">
          <div><StoreIcon /></div>
          <div><BusinessIcon titleAccess="Company Name" /></div>

          {/* <div><MapIcon /></div> */}
          {/* <div><LocationCityIcon /></div> */}
          {/* <div><PhoneIcon /></div> */}
          {/* <div><EmailIcon /></div> */}
          {/* <div><PlaceIcon /></div> */}
          {/* <div><ScheduleIcon /></div> */}
          <div><InfoIcon /></div>
          {/* <div><EventIcon /></div> */}
          <div><MonetizationOnIcon titleAccess="Delivery Fee (VAT included)" /></div>
          <div><LocalShippingIcon titleAccess="Delivery Fee Threshold (VAT included)" /></div>
        </div>
        <div className="tableBody">
          {customersToShow
            .slice(customersPageNumber * pageSize, (customersPageNumber + 1) * pageSize)
            .map((customer) => (
              <div
                key={customer.name}
                className={`row ${auth.user.role < 2 ? 'editable' : ''}`}
                role="presentation"
                onClick={() => switchModal('edit', 'customer', customer)}
              >
                <div>{customer.name}</div>
                <div>{customer.company || '-'}</div>
                {/* <div>{customer.area}</div> */}
                {/* <div>{customer.city}</div> */}
                {/* <div>{customer.phone ? <DoneIcon color="success" /> : <QuestionMarkIcon color="error" />}</div> */}
                {/* <div>{customer.email ? <DoneIcon color="success" /> : <QuestionMarkIcon color="error" />}</div> */}
                {/* <div>{customer.address ? <DoneIcon color="success" /> : <QuestionMarkIcon color="error" />}</div> */}
                {/* <div>{customer.time}</div> */}
                <div><CircleIcon color={customer.active ? 'success' : 'error'} /></div>
                {/* <div>{customer.latest}</div> */}
                <div>{customer.delivery_fee_threshold || '-'}</div>
                <div>{customer.delivery_fee_vat_inc || '-'}</div>
              </div>
            ))}
        </div>
        <div className="tableFooter">
          <div>
            <StyledSelectGroup>
              <label htmlFor="group">
                {`Group (${filter.group === '0'
                  ? customers.length
                  : customers.filter((item) => item.groupId === +filter.group).length})`}
              </label>
              <select id="group" onChange={groupFilter}>
                <option value="0">All</option>
                {groups.map((item) => (<option key={`group-${item.id}`} value={item.id}>{item.name}</option>))}
              </select>
              <div className="bottomBorder" />
            </StyledSelectGroup>
          </div>
          <div />
          <div>
            <StyledSelectGroup>
              <label htmlFor="city">{`City (${customersToShow.length})`}</label>
              <select id="city" onChange={cityFilter} value={filter.city}>
                <option value="all">All</option>
                {cities.map((item) => (<option key={item} value={item}>{item}</option>))}
              </select>
              <div className="bottomBorder" />
            </StyledSelectGroup>
          </div>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div className="pageGroup">
            <button
              type="button"
              onClick={() => setCustomersPageNumber(customersPageNumber - 1)}
              disabled={customersPageNumber < 1}
            >
              {'<'}
            </button>
            <div>{`${Math.min(customersPageNumber + 1, customersMaxPageNumber)} / ${customersMaxPageNumber}`}</div>
            <button
              type="button"
              onClick={() => setCustomersPageNumber(customersPageNumber + 1)}
              disabled={customersPageNumber + 1 >= customersMaxPageNumber}
            >
              {'>'}
            </button>
          </div>
        </div>
      </div>
    </StyledCustomers>
  );
};

export default Customers;
